import { render, staticRenderFns } from "./depthOfTheArticleDetails.vue?vue&type=template&id=4ab03019&scoped=true"
import script from "./depthOfTheArticleDetails.vue?vue&type=script&lang=js"
export * from "./depthOfTheArticleDetails.vue?vue&type=script&lang=js"
import style0 from "./depthOfTheArticleDetails.vue?vue&type=style&index=0&id=4ab03019&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ab03019",
  null
  
)

export default component.exports