import request1 from '../../util/loginrequest'
import request from '../../util/request'
// 1.获取深度文章列表
export const getDepthArticlelist = (params) => {
  return request({
    url: '/api/demo/Articles/list',
    method: 'post',
    data: {
      limit: params.limit || '',
      page: params.page || '',
      title: params.title || '',
      id: params.id
    }

  })
}
// 收藏文章
export const toAttentionProject = (params) => {
  return request1({
    url: '/api/demo/subscribe/collectAdd',
    method: 'post',
    data: {
      title_id: params.title_id || '',
      type: params.type || '',
      user_id: params.user_id || ''
    }
  })
}
// 取消收藏文章
export const cancelAttentionProject = (params) => {
  return request1({
    url: '/api/demo/subscribe/collectDel',
    method: 'post',
    data: {
      title_id: params.title_id || '',
      type: params.type || '',
      user_id: params.user_id || ''
    }
  })
}
// 深度文章阅读排行
export const articleorder = (params) => {
  return request({
    url: 'api/demo/Articles/paihang',
    method: 'post',
    data: {
      limit: params.limit || '',
      page: params.page || '',
      title: params.title || '',
      id: params.id
    }

  })
}
