<template>
  <div class="typeArea content">
    <div class="content-left">
      <div class="left-title">
        {{depthArticledetail.post_title}}
      </div>
      <div class="left-info">
        <div class="info">
          <div class="info-item">
            <img
              src="../../../../assets/image/深度文章_slices/详情页/时钟.png"
              alt=""
            >
            {{depthArticledetail.dateformatepush}}
          </div>
          <div class="info-item">
            <img
              src="../../../../assets/image/深度文章_slices/详情页/作者.png"
              alt=""
            >
            {{depthArticledetail.user}}
          </div>
          <div
            class="info-item"
            @click="newurlclick"
          >
            <img
              src="../../../../assets/image/深度文章_slices/详情页/网站.png"
              alt=""
            >
            {{depthArticledetail.url_name}}
          </div>
          <div
            class="info-item"
            @click="collectionClick"
          >
            <img
              v-if="collection"
              src="../../../../assets/image/深度文章_slices/详情页/收藏.png"
              alt=""
            >
            <img
              v-else
              src="../../../../assets/image/深度文章_slices/详情页/未收藏.png"
              alt=""
            >

            收藏
          </div>
        </div>
        <div class="share">
          <img
            src="../../../../assets/image/深度文章_slices/详情页/微信.png"
            alt=""
          >
          分享给小伙伴
          <!-- <div
            class="wxer-code"
            id="wxer-code"
            ref="paycode"
            :style="{transform: 'scale('+$store.state.suofangbi+')'}"
          > -->
          <!-- <img
              src="../../../../assets/image/深度文章_slices/详情页/科研人个人二维码@2x.png"
              alt=""
            > -->
          <!-- </div>
           -->
          <div class="wxer-code">
            <vue-qr
              ref="Qrcode"
              :logoSrc='imgUrl'
              :text="codeUrl"
              :size="200"
              :margin="8"
              qid="testQrId"
            ></vue-qr>
            <!-- <a
                  :href="exportLink"
                  @click="downloadImg"
                  :download="downloadFilename"
                >下载二维码</a> -->

          </div>
        </div>

      </div>
      <div
        class="background-content" style="white-space:pre-wrap"
        v-html="depthArticledetail.post_content"
      >

      </div>
      <!-- <div class="main-image">
        <img
          src="../../../../assets/image/深度文章_slices/详情页/图像 103@2x.png"
          alt=""
        >
      </div>
      <div class="Introduction">
        导语：基金虐我千百遍，我待基金如初恋。春节前后不断有朋友和部分单位邀请去帮看基金申请书，拜读了各种形式的本子。记得2010年底自己开始写国家基金项目的申请，写啥？如何写？心中完全没底。拜读他人已经获得资助的申请书，听单位举办的交流会，学习网上各自交流经验等等，边听、边学、边做笔记，十八般武艺都用上了。那年自己为主写了一个面上项目，和他人合作写了一个地区项目。记得基金发榜的那天，好像是周五晚上。
      </div>
      <div class="introduction-content">
        <div class="content-text">
          导语：基金虐我千百遍，我待基金如初恋。
        </div>
        <div class="content-title">
          第一，要明白，你为啥要申请国家基金？
        </div>
        <div class="content-text">
          不可否认，申报国家自然科学基金的驱动力有千万种：我等研究单位的人本身就要靠它生存，有为了上职称，单位必须要求申报的，同事报了我不能落后的………不管如何，既然决定要申报，申报前就要有背水一战的决心！
        </div>
        <div class="content-text">
          既然要申请，熟读每年国家基金委的申报指南是首要的、必要的！这几年国家基金申报政策每年都有一些小的变化，申报前要好好的读！避免犯一些形式审查的错误，如签名、身份证、限项、提交方式等等，总有人犯了一些不该犯的错误，耽误了一年的申请。
        </div>
        <div class="content-title">
          第二，准备申报基金前要明白两个问题：一、写啥？二、怎么写？
        </div>
        <div class="content-text">
          写啥既包括了申请哪一类基金，是申请青年、面上、重点，还是其它？也包括了对应的是自己的哪方面研究工作适合这些类别的基金。
        </div>
        <div class="content-text">
          如果你的背景很好，有多篇文章发表，包括在超一流杂志上发表，你考虑申请面上、甚至重点项目。目前，重点项目竞争太激烈！有这样好背景的初申请者可以考虑先申请面上项目，那样你的命中率会很高。
        </div>
        <div class="content-text">
          如果你的背景一般，特别是博士毕业不久，有几篇文章发表，但发表在一般杂志上，考虑先申请青年基金，或者西部地区可以申请地区基金项目。
        </div>
        <div class="content-text">
          如果你背景特一般，又没有文章发表，还没有很好的前期研究基础。建议不要浪费自己时间写申请书！到一些单位帮看申请书，总碰上在读研究生或者硕士刚毕业，没有包括中文期刊的文章发表，也没有前期研究工作，查一些文献、资料，写来一个本子。
        </div>
        <div class="content-title">
          第三，根据自身条件定好了要申请的基金类别，接下来就是考虑申请书要写啥。
        </div>
        <div class="content-text">
          写啥！国家自然科学基金偏重基础，注重创新！因此，一定要把握这两点！你要申请的研究项目或者回答该领域中的某一个未解决的问题，或者目前的前期工作已有新的发现，或者材料新颖和技术创新等等。总之，要让评委觉得你的项目至少具有一定的创新性！获得资助的可能性就高！当然，这些创新是立足在科学实验（你的实验结果或者他人的结果）上，而不是缺乏科学证据的奇思怪想。
        </div>
         -->
      <div class="copyrightStatement">
        版权声明： <br>本网站所有注明“来源：科研人”或“来源：科研人原创”的文字、图片和音视频资料，版权均属于科研人网站所有。非经授权，任何媒体、网站或个人不得转载，授权转载时须注明“来源：科研人（www.keyanpro.com）”。其它来源的文章系转载文章，本网所有转载文章系出于传递更多信息之目的，转载内容不代表本站立场。不希望被转载的媒体或个人可与我们联系，我们将立即进行删除处理。 </div>
    </div>
    <div class="content-right">
      <div class="read-ranking">
        <div class="read-title">
          <div class="shuxian"></div>
          <div class="title-text">
            阅读排行
          </div>
        </div>
        <div class="read-content">
          <div
            class="content-item"
            v-for="item in depthArticleListbytop.slice(0,5)"
            :key="item.id"
          >
            <div class="dian"></div>
            <div
              class="item-text"
              @click="depthOfTheArticleDetails(item.id)"
            >
              <div class="text-top">
                {{item.post_title}}
              </div>
              <div class="text-time">
                {{item.dateformatepush}}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { getDepthArticlelist, toAttentionProject, cancelAttentionProject, articleorder } from '../../../../api/details/depthOfTheArticleDetails'
// eslint-disable-next-line no-unused-vars
import { formatDate, formatDate1, makePy, checkCh, mkRslt } from '../../../../util/tools'
// eslint-disable-next-line no-unused-vars
import QRCode from 'qrcodejs2'
import vueQr from 'vue-qr'
import { Addhistoricalrecordwz } from '../../../../api/myRecentInformation'
export default {
  data () {
    return {
      codeUrl: '', // 二维码地址或内容
      imgUrl: '', // 二维码中间的logo图
      exportLink: '',
      downloadFilename: '', // 下载的图片文件名
      // 深度文章详情数据
      depthArticledetail: '',
      // 收藏
      collection: false,
      // 深度文章阅读排行
      depthArticleListbytop: [],
      // 控制是否显示分享
      isshowshare: false

    }
  },
  created () {
    // 获取数据
    this.getData();
    this.goTop();
    const user = JSON.parse(localStorage.getItem('usermessage'))
    if (user && user.id && (user.id !== null || user.id !== '')) {
      this.$data.isshowshare = true
    }
  },
  components: {
    vueQr
  },
  mounted () {
    if (this.$data.isshowshare) {
      this.qrcode()
    } else {
      this.qrcode1()
    }
  },
  methods: {
    // 数据按照字段名降序排序
    sortDesByKey (ary, key) {
      return ary.sort((a, b) => {
        const x = a[key]
        const y = b[key]
        return ((x < y) ? 1 : (x > y) ? -1 : 0)
      })
    },
    goTop () {
      cancelAnimationFrame(this.timer)
      const self = this
      self.timer = requestAnimationFrame(function fn () {
        const oTop = document.body.scrollTop || document.documentElement.scrollTop
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop = oTop - 50
          self.timer = requestAnimationFrame(fn)
        } else {
          cancelAnimationFrame(self.timer)
        }
      })
    },
    // 格式化日期格式
    formateDateList (arr) {
      arr.forEach(element => {
        if (element.published_time) {
          element.dateformatepush = formatDate1(parseInt(element.published_time)).trim()
        }
      })
    },
    // 新闻链接点击跳转
    newurlclick () {
      const url = this.$data.depthArticledetail.post_source
      window.open(url)
    },
    qrcode () {
      var scene = this.$store.state.userid
      const address = this.$store.state.wxArticlebaseurl + '&scene=' + scene + '&wid=' + this.$route.params.id
      this.$data.codeUrl = address
    },
    qrcode1 () {
      // const vip = JSON.parse(localStorage.getItem('usermessage')).level
      const address = this.$store.state.wxArticlebaseurl
      // eslint-disable-next-line no-unused-vars
      //
      this.$data.codeUrl = address
    },
    // 数据获取
    getData () {
      const that = this
      const id = this.$route.params.id
      // 获取深度文章详情
      getDepthArticlelist({ limit: 12, page: 1, id: id }).then(res => {
        console.log('深度文章详情', res)
        that.formateDateList(res.data)
        // 该方法会给res.data中添加dateformate字段用来存储格式化后的创建时间
        that.$data.depthArticledetail = res.data[0]
      }, err => console.log(err))
      // 获取深度文章排行
      articleorder({ limit: 12, page: 1 }).then(res => {
        console.log('深度文章排行', res)
        that.formateDateList(res.data)
        // console.log('排序前', res.data)
        // console.log('排序后', that.sortDesByKey(res.data, 'id'))
        // res.data = that.sortDesByKey(res.data, 'is_top')
        // 该方法会给res.data中添加dateformate字段用来存储格式化后的创建时间
        that.$data.depthArticleListbytop = res.data
      }, err => console.log(err))
    },
    // 点击实现收藏
    collectionClick () {
      const id = this.$data.depthArticledetail.id
      const type = 4
      const userid = this.$store.state.userid
      const params = {
        title_id: id,
        type: type,
        user_id: userid
      }
      if (this.$data.collection === false) {
        this.$data.collection = !this.$data.collection
        toAttentionProject(params).then(res => {
          console.log('成功', res)
        }, err => {
          console.log('失败', err)
        })
      } else {
        this.$data.collection = !this.$data.collection
        cancelAttentionProject(params).then(res => {
          console.log('成功', res)
        }, err => {
          console.log('失败', err)
        })
      }
      window._czc.push(['_trackEvent', '点击', '收藏文章'])
    },
    // 跳转到更多文章详情页
    depthOfTheArticleDetails (id) {
      localStorage.setItem('navActive', 5)
      this.$store.commit('setnavActive', 5)
      const tid = id
      const uid = this.$store.state.userid
      const params = {
        title_id: tid,
        user_id: uid
        // type: 1
      }
      Addhistoricalrecordwz(params).then((res) => {
        console.log('添加历史记录成功', res)
      }, (err) => {
        console.log('添加历史记录异常', err)
      })
      this.$router.push({
        path: `depthOfTheArticleDetails${id}`
      })
      this.$router.go(0)
      window._czc.push(['_trackEvent', '点击', '跳转到更多文章详情页'])
    }
  }

}
</script>

<style lang="scss" scoped>
// 版心
.typeArea {
  width: 1200px;
  margin: 0 auto;
}
.content {
  margin-top: 58px;
  display: flex;
  justify-content: space-between;
  .content-left {
    width: 760px;
    .left-title {
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 50px;
      color: #282828;
      margin-bottom: 29px;
    }
    .left-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 51px;
      .info {
        display: flex;
        justify-content: left;
        align-items: center;
        .info-item {
          cursor: pointer;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #717171;
          display: flex;
          justify-content: left;
          align-items: center;
          margin-right: 22px;
          img {
            width: 14px;
            height: 14px;
            margin-right: 5px;
          }
        }
      }
      .share {
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: right;
        align-items: center;
        img {
          width: 15.56px;
          height: 12.57px;
          margin-right: 6px;
        }
        .wxer-code {
          transform-origin: 0 0;
          display: none;
          position: absolute;
          top: 25px;
          left: 13px;
          border: 5px solid #000;
          &::after {
            content: "";
            position: absolute;
            top: -10px;
            left: 28px;
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid #000;
          }
          img {
            width: 72px;
            height: 72px;
            margin: 0px;
          }
        }
      }
      .share:hover {
        .wxer-code {
          display: block !important;
        }
      }
    }
    .main-image {
      border: 1px dashed #888888;
      margin-bottom: 21px;
      img {
        width: 760px;
        height: 506.16px;
      }
    }
    .Introduction {
      background-color: #f6f7f9;
      width: 760px;
      box-sizing: border-box;
      padding: 20px 20px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 33px;
      color: #444444;
      margin-bottom: 20px;
    }
    .introduction-content {
      .content-text {
        font-size: 17px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 30px;
        color: #333333;
        margin-bottom: 46px;
      }
      .content-title {
        font-size: 17px;
        font-family: Source Han Sans CN;
        font-weight: 600;
        line-height: 30px;
        color: #333333;
        margin-bottom: 46px;
      }
    }
    .copyrightStatement {
      background-color: #f6f7f9;
      width: 760px;
      box-sizing: border-box;
      padding: 20px 20px;
      padding-bottom: 9px;
      margin-bottom: 28px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: #999999;
    }
  }
  .content-right {
    .read-ranking {
      width: 320px;
      border: 1px solid #f1f2f6;
      top: -25px;
      right: 0px;
      padding: 31px 26px;
      box-sizing: border-box;
      .read-title {
        display: flex;
        justify-content: left;
        align-items: center;
        margin-bottom: 22px;
        .shuxian {
          width: 4px;
          height: 12px;
          border-radius: 2px;
          background-color: #5e068c;
          margin-right: 3px;
        }
        .title-text {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }
      }
      .read-content {
        display: flex;
        flex-direction: column;
        .content-item {
          margin-bottom: 22px;
          display: flex;
          .dian {
            width: 6px;
            height: 6px;
            box-sizing: border-box;
            margin-right: 5px;
            border-radius: 50%;
            background-color: #5e068c;
            transform: translate(0px, 6px);
          }
          .item-text {
            flex: 1;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .text-top {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 18px;
            color: #282828;
            margin-bottom: 11px;
          }
          .text-time {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #717171;
          }
        }
      }
    }
  }
}
</style>
